<template>
  <b-modal
    id="apac-sus-guide-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
    no-close-on-backdrop
    no-close-on-esc 
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="susGuide?.id">
          Atualizar guia APAC (Autorização de Procedimentos Ambulatoriais) - N° {{ susGuide?.guide?.guide_number }}
        </span>
        <span v-else>
          Nova guia APAC (Autorização de Procedimentos Ambulatoriais) - N° {{ sus_information?.current_apac_guide_number + 1 }}
        </span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <div class="around-content">
      <!-- Estabelecimento -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do estabelecimento de saúde (Solicitante)</p>
        </b-col>
        <b-col class="mt-3" cols="10">
          <b-form-group>
            <label for="request_id">Nome do estabelecimento de saúde</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="clinic.name"
              :readonly="true"
              :state="errors.name_establishment ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.name_establishment">
              {{ errors.name_establishment }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="cnaes_code">CNES</label>
            <b-form-input
              id="cnaes_code"
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="sus_information.cnaes_code"
              :readonly="true"
              :state="errors?.cnaes_code ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.cnaes_code">
              {{ errors.cnaes_code }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Paciente -->
      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Identificação do Paciente</p>
        </b-col>  
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_name">Nome do paciente</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name"
              :readonly="readonly"
              :state="errors.patient_name ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.patient_name">
              {{ errors.patient_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="request_number">N° da solicitação</label>
            <b-form-input
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.request_number"
              :readonly="readonly"
              :state="errors.request_apac ? false : null"
              />
              <b-form-invalid-feedback v-if="errors.request_apac">
              {{ errors.request_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="professional_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="000-0000-0000-0000"
              v-mask="'###-####-####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cns"
              :readonly="readonly"
              />
          </b-form-group>
        </b-col>  
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_birthday">Data de nascimento</label>
            <b-form-input
              v-model="form.patient.birthday"
              id="patient_birthday"
              name="patient_birthday"
              class="full"
              type="date"
              :readonly="readonly"
              :state="errors.date_birth_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.date_birth_apac">
              {{ errors.date_birth_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>    
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_gender">Sexo</label>
            <b-form-radio-group
              id="patient_gender"
              v-model="form.patient.gender"
              name="patient_gender"
              class="pt-2"
              :disabled="readonly"
              :state="errors.sex_apac ? false : null"
            >
              <b-form-radio value="M">Masc.</b-form-radio>
              <b-form-radio value="F">Fem.</b-form-radio>
            </b-form-radio-group>
            <b-form-invalid-feedback v-if="errors.sex_apac">
              {{ errors.sex_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>        
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_ethnicity">Raça/Cor</label>
            <multiselect
              id="state"
              v-model="form.patient.ethnicity"
              :options="ethnicityOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors.race_color_apac ? false : null">
              {{ errors.race_color_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_ancestry">Etnia</label>
            <multiselect
              id="state"
              v-model="form.patient.ancestry"
              :options="ancestryOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors.ethnicity_apac ? false : null">
              {{ errors.ethnicity_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_mother_name">Nome da mãe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.mother_name"
              :readonly="readonly"
              :state="errors.mother_name_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.mother_name_apac">
              {{ errors.mother_name_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_mother_phone">Telefone de contato</label>
            <b-form-input
              placeholder="(00) 00000-0000"
              v-mask="'(##) ####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.telephone_mother"
              :readonly="readonly"
              :state="errors.mother_contact_telephone_apac ? false : null"
              />
              <b-form-invalid-feedback v-if="errors.mother_contact_telephone_apac">
                {{ errors.mother_contact_telephone_apac }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_responsible_name">Nome do responsável</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name_responsible"
              :readonly="readonly"
              :state="errors.name_responsible_apac ? false : null"
              />
              <b-form-invalid-feedback v-if="errors.name_responsible_apac">
                {{ errors.name_responsible_apac }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_responsible_phone">Telefone de contato</label>
            <b-form-input
              v-mask="'(##) ####-####'"
              placeholder="(00) 00000-0000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.telephone_responsible"
              :readonly="readonly"
              :state="errors.responsible_contact_telephone_apac ? false : null"
              />
            <b-form-invalid-feedback v-if="errors.responsible_contact_telephone_apac">
              {{ errors.responsible_contact_telephone_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_zipcode">CEP</label>
            <b-form-input
              v-mask="'#####-###'"
              placeholder="00000-000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.zipCode"
              :readonly="readonly"
              :state="errors?.cep_apac ? false : null"
              @keyup="searchCEP"
              />
            <b-form-invalid-feedback v-if="errors?.cep_apac">
              {{ errors.cep_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_ibge_code">Cód. IBGE Município</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.ibge_code"
              :readonly="readonly"
              v-mask="'#######'"
              :state="errors?.ibge_apac ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.ibge_apac">
              {{ errors.ibge_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_street_code">Cód. Logradouro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.street_code"
              :readonly="readonly"
              v-mask="'#######'"
              :state="errors?.address_apac_street_code ? false : null"
              />
              <b-form-invalid-feedback v-if="errors?.address_apac_street_code">
                {{ errors.address_apac_street_code }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address">Logradouro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.address"
              :readonly="readonly"
              :state="errors?.address_apac_address ? false : null"
              />
              <b-form-invalid-feedback v-if="errors?.address_apac_addres">
                {{ errors.address_apac_addres }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_number">Número</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              ref="addressNumber"
              class="form-control"
              v-model="form.patient.address.number"
              :readonly="readonly"
              :state="errors?.address_apac_number ? false : null"
              />
              <b-form-invalid-feedback v-if="errors?.address_apac_number">
                {{ errors.address_apac_number }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_number">Complemento</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.complement"
              :readonly="readonly"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_neighborhood">Bairro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.neighborhood"
              :readonly="readonly"
              :state="errors?.address_apac_neighborhood ? false : null"
              />
              <b-form-invalid-feedback v-if="errors?.address_apac_neighborhood">
                {{ errors.address_apac_neighborhood }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_city">Município</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.city"
              :readonly="readonly"
              :state="errors?.municipality_apac ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.municipality_apac">
              {{ errors.municipality_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_state">Estado</label>
            <multiselect
              id="addressState"
              v-model="form.patient.address.state"
              :options="statesOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              :allowEmpty="false"
              placeholder="UF"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.state_apac">
              {{ errors.state_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Justificativa -->
      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Justificativa do(s) procedimento(s) solicitado(s)</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="request_id">CID 10 Principal</label> 
            {{ errors?.cid_main_apac }}
            <multiselect
              v-model="form.cid_1"
              id="cid1"
              track-by="id"
              label="label"
              :options="cid10"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              @search-change="searchCid10"
              @select="selectCid_1"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
            <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.cid_main_apac ? false : null">
              {{ errors.cid_main_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="request_id">CID 10 secundário</label>
            <multiselect
              v-model="form.cid_2"
              id="cid2"
              track-by="id"
              label="label"
              :options="cid10"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              @search-change="searchCid10"
              @select="selectCid_2"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
            <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.cid_secondary_apac ? false : null">
              {{ errors.cid_secondary_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="request_id">Motivo de saída/permanência</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.reason_leaving_staying"
              :readonly="readonly"
              :state="errors?.reason_leaving_staying_apac ? false : null"
            />
            <b-form-invalid-feedback :state="errors?.reason_leaving_staying_apac ? false : null">
              {{ errors.reason_leaving_staying_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="request_id">Descrição do diagnóstico</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.description"
              :readonly="readonly"
              :state="errors?.diagnosis_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.diagnosis_apac">
              {{ errors.diagnosis_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="request_id">Observações</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.observation"
              :readonly="readonly"
              :state="errors?.observations_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.observations_apac">
              {{ errors.observations_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Solicitação -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Solicitação</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="requesting_professional">Nome do profissional</label>
            <ProfessionalFieldModule
              :clinic="clinic"
              :professional="form.requesting_professional"
              :readonly="readonly"
              @update-professional="updateProfessional"
            />
            <b-form-invalid-feedback :state="errors?.professional_name_apac ? false : null">
              {{ errors.professional_name_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="requesting_date">Data de solicitação</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.requesting_date"
              name="requesting_date"
              format="DD/MM/YYYY"
              value-type="date"
              id="requesting_date"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <b-form-invalid-feedback :state="errors.request_date_apac ? false : null">
              {{ errors.request_date_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="requesting_professional_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="000-0000-0000-0000"
              v-mask="'###-####-####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.requesting_professional.cns"
              :readonly="true"
              :state="errors.requesting_professional_cns ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.requesting_professional_cns">
              {{ errors.requesting_professional }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Autorização -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Autorização</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="professional_name">Nome do profissional autorizador</label>
            <ProfessionalFieldModule
              :clinic="clinic"
              :professional="form.authorizing_professional"
              :readonly="readonly"
              @update-professional="updateAutorizador"
            />
            <b-form-invalid-feedback :state="errors.authorizing_professional_apac ? false : null">
              {{ errors.authorizing_professional_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col> 
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorizer_issuing_body_code">Cód. Orgão Emissor</label>
            <b-form-input
              v-mask="'#########'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.issuing_body_code"
              :readonly="readonly"
              :state="errors?.issuer_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.issuer_apac">
              {{ errors.issuer_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>       
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorizer_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              v-mask="'###-####-####-####'"
              placeholder="000-0000-0000-0000"
              autocomplete="off"
              class="form-control"
              v-model="form.authorizing_professional.cns"
              :readonly="true"
              :state="errors?.national_authorizer_card_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.national_authorizer_card_apac">
              {{ errors.national_authorizer_card_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorization_date">Data de autorização</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.authorization_date"
              name="authorization_date"
              format="DD/MM/YYYY"
              value-type="date"
              id="authorization_date"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <b-form-invalid-feedback :state="errors?.authorization_date_apac ? false : null">
              {{ errors.authorization_date_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorizer_cns">N° da autorização (APAC)</label>
            <b-form-input
              placeholder="000000000000-0"
              v-mask="'#############-#'"
              autocomplete="off"
              class="form-control"
              v-model="form.apac_authorization_number"
              :readonly="readonly"
              :state="errors.authorization_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.authorization_apac">
              {{ errors.authorization_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="validity_period_start">Período de validade da APAC</label>
            <div class="d-flex">
              <date-picker
                placeholder="DE 00/00/0000"
                v-model="form.validity_period_start"
                name="validity_period_start"
                format="DD/MM/YYYY"
                value-type="date"
                id="validity_period_start"
                :lang="langDatePicker"
                :clearable="false"
                class="full mr-2"
                :disabled="readonly"
              />
              <b-form-invalid-feedback :state="errors?.validity_period_apac ? false : null">
                {{ errors.validity_period_apac }}
              </b-form-invalid-feedback>

              <date-picker
                placeholder="ATÉ 00/00/0000"
                v-model="form.validity_period_end"
                name="validity_period_end"
                format="DD/MM/YYYY"
                value-type="date"
                id="validity_period_end"
                :lang="langDatePicker"
                :clearable="false"
                class="full"
                :disabled="readonly"
              />
            </div>
            <b-form-invalid-feedback :state="errors?.validity_period_end ? false : null">
                {{ errors.validity_period_end }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <ProceduresPerformed
        :checkedItems="procedures"
        :readonly="!readonly"
        :clinicHealthPlanId="sus_information?.clinic_health_plan?.id"
        @update-procedures="updateProcedures"
        :requestFields="requiredField"
        :type="'apac'"
      />

      <!-- Executante -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do estabelecimento de saúde (Executante)</p>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="executing_establishment_name">Nome fantasia do estabelecimento de saúde executante</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.executing_establishment_name"
              :readonly="readonly"
              :state="errors.fantasy_name_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.fantasy_name_apac">
              {{ errors.fantasy_name_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="executing_establishment_cnes">CNES</label>
            <b-form-input
              placeholder="Descrever"
              v-mask="'#######'"
              autocomplete="off"
              class="form-control"
              v-model="form.executing_establishment_cnes"
              :readonly="readonly"
              :state="errors.cnes_executant_apac ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.cnes_executant_apac">
              {{ errors.cnes_executant_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>  

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="susGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updatesusGuide"
          :disabled="susGuide.situation === 'Cancelada'"
          v-if="susGuide && susGuide.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>

    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import ProceduresPerformed from './Modules/ProceduresPerformed.vue';
import { SusBatch } from '@/modules/sus/manageGuides/utils/statuses'
import { saveAs } from 'file-saver'
import api from '@/modules/sus/api'
import moment from 'moment';
import * as cep from 'cep-promise'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    ProceduresPerformed,
  },
  props: {
    sus_information_setting: Object,
    patient: {
      type: Object,
      required: true,
    },
    clinicHealthPlan: {
      type: Object
    },
    susGuide: {
      type: Object,
      default: () => ({})
    },
    checkedItems: Array,
    appointmentId: String,
    clinicHealthPlanId: String
  },
  data() {
    return {
      user: getCurrentUser(),
      cid10: [],
      isLoading: true,
      clinic: getCurrentClinic(),
      form: this.getDefaultForm(),
      loading: true,
      procedures: [],
      readonly: true,
      requiredField: {},
      sus_information: {},
      errors: {},
      nationalities: [],
      statesOptions: [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
      ],
      ancestryOptions:[
        'Africana',
        'Asiática',
        'Latina',
        'Indígena',
        'Árabe',
        'Eslava',
        'Judaica',
        'Romani'
      ],
      ethnicityOptions: [
        'Branca',
        'Preta',
        'Parda',
        'Amarela',
        'Indígena',
      ],
      langDatePicker: {
        formatLocale: {
          months: moment.months(),
          monthsShort: moment.monthsShort(),
        },
      },
    };
  },
  async mounted() {
    try {
      const { data } = await api.getInformationSusSettingByClinicId(this.clinic.id);
      this.sus_information = data;

      this.getRequiredFields();
    
      if (this.nationalities.length === 0) {
        this.api.getNationalities().then(async res => {
          this.nationalities = res.data;
        })
      }

    } catch (error) {
      console.error('Erro ao carregar dados do SUS', error);
    }
  },
  methods: {
    isReadonly() {
      this.readonly = Boolean(
        this.localSusGuide?.id &&
        (this.localSusGuide.sus_batch !== null && this.localSusGuide?.sus_batch?.status !== SusBatch.BATCH_STATUS_TO_SENT) && 
        ['Finalizada'].includes(this.localSusGuide?.situation)
      );    
    },
    formatCid(cid) {
      return cid 
        ? { label: `${cid.code} - ${cid.description}`, value: cid.id } 
        : null;
    },
    formatDate(date) {
      return date ? moment(date).toDate() : null;
    },
    getCheckedItems(){
      if(this.checkedItems){
        this.procedures = this.checkedItems.map((item, index) => ({
          id: null,
          item: item.item,
          item_id: item.item_id,
          code: item.item.code,
          name: item.item.name,
          attendance_date: moment(item?.appointment?.start_datetime).toDate(),
          quantity: 1,
          appointment_item_id: item.id,
          unitary_value: item.item.health_plan_props.value,
          total_value: item.item.health_plan_props.value,
          is_principal: index === 0
        }));

        this.form.procedures = this.procedures;
      }
    },
    getDefaultForm() {
      return {
        request_number: '',
        cid_1: null,
        cid_2: null,
        observation: null,
        description: '',
        reason_leaving_staying: '',
        clinic: {
          name: '',
          address: '',
        },
        procedures: [],
        requesting_date: null,
        authorization_date: null,
        apac_authorization_number: '',
        patient: {
          cns: null,
          name: null,
          gender: null,
          birthday: null,
          nationality: null,
          ethnicity: null,
          ancestry: null,
          cellphone: null,
          email: '',
          address: {
            zipCode: '',
            ibge_code: null,
            street_code: null,
            address: '',
            complement: '',
            neighborhood: ''
          }
        },
        validity_period_start: null,
        validity_period_end: null,
        requesting_professional: {
          name: '',
          cns: '',
        },
        authorizing_professional: {
          name: '',
          cns: '',
        },
        issuing_body_code: '',
        sus_information: {
          cnaes_code: null
        },
        executing_establishment_name: '',
        executing_establishment_cnes: '',
      };
    },
    async getRequiredFields() {
      try {
        const requiredfield = {};
        if (this.clinic.id) {
          const { data } = await api.getSusGuideRequiredFields(this.clinic.id);   
          
          Object.keys(data).map(field => {
            if(field.includes("apac") && data[field] === true){
              requiredfield[`${field}`] = true;
            }
          });
        }

        this.requiredField = requiredfield;        
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onShow() {
      this.loading = false;
      this.isReadonly();
      
      if(this.susGuide && Object.values(this.susGuide).length > 1){
        this.updateLocalSusGuide(this.susGuide);  
      }
      
      if(this.patient && Object.values(this.patient).length > 1){
        this.updateLocalPatient(this.patient);
      }

      this.getCheckedItems();
    },
    closeModal() {
      this.form = this.getDefaultForm();
      this.localSusGuide = {};
      this.procedures = [];
      this.form.procedures = [];
      this.$emit('onHide');
      this.$bvModal.hide('apac-sus-guide-modal');
    },
    searchCEP() {
      if (this.form.patient?.address?.zipCode.length === 9) {
        cep(this.form.patient.address.zipCode)
          .then(res => {
            this.form.patient.address.address = res.street
            this.form.patient.address.neighborhood = res.neighborhood
            this.form.patient.address.state = res.state
            this.form.patient.address.city = res.city	
            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            console.log('Err', err)
          })
      }
    },
    isValidForm() {
      const errors = {};

      if(!this.form.procedures.length) {   
        errors.procedures = "Campo obrigatório";     
        this.$toast.error('Obrigatório incluir procedimento a guia!');
      }

      if(this.requiredField.name_establishment_apac && !this.clinic.name) {
        // Nome do estabelecimento de saúde
        errors.name_establishment = "Campo obrigatório";
      }

      if(this.requiredField.cnes_applicant_apac && !this.sus_information.cnaes_code) {
        // CNES estabelecimento solicitante
        errors.cnaes_code = "Campo obrigatório";      
      }
      
      if(this.requiredField.patient_name_apac && !this.form.patient.name) {
        // Nome do paciente
        errors.patient_name = "Campo obrigatório";
      }

      if(this.requiredField.request_apac && !this.form.request_number) {
        // N° da solicitação
        errors.request_apac = "Campo obrigatório";
      }

      if(this.requiredField.national_card_apac && !this.form.requesting_professional.cns) {
        // Professional Cartão Nacional de Saúde (CNS)
        errors.requesting_professional_cns = "Campo obrigatório";
      }
      
      if(this.requiredField.date_birth_apac && !this.form.patient.birthday) {
        // Data de nascimento
        errors.date_birth_apac = "Campo obrigatório";
      }
      
      if(this.requiredField.sex_apac && !this.form.patient.gender) {
        // Sexo
        errors.sex_apac = "Campo obrigatório";
      }

      if(this.requiredField.race_color_apac && !this.form.patient.ethnicity) {
        // Raça/Cor
        errors.race_color_apac = "Campo obrigatório";
      }

      if(this.requiredField.ethnicity_apac && !this.form.patient.ancestry) {
        // Etnia
        errors.ethnicity_apac = "Campo obrigatório";
      }

      if(this.requiredField.mother_name_apac && !this.form.patient.mother_name) {
        // Nome da mãe
        errors.mother_name_apac = "Campo obrigatório";
      }

      if(this.requiredField.mother_contact_telephone_apac && !this.form.patient.telephone_mother) {
        // Telefone de contato da mãe
        errors.mother_contact_telephone_apac = "Campo obrigatório";
      }


      if(this.requiredField.name_responsible_apac && !this.form.patient.name_responsible) {
        // Nome do responsável
        errors.name_responsible_apac = "Campo obrigatório";
      }

      if(this.requiredField.responsible_contact_telephone_apac && !this.form.patient.telephone_responsible) {
        // Telefone de contato do responsável
        errors.responsible_contact_telephone_apac = "Campo obrigatório";
      }

      if(this.requiredField.address_apac ) {
        // Endereço (Rua, N°, Bairro)
        if (!this.form.patient.address.neighborhood) {
          errors.address_apac_neighborhood = "Campo obrigatório";
        }
        if (!this.form.patient.address.number) {
          errors.address_apac_number = "Campo obrigatório";
        }
        if (!this.form.patient.address.address) {
          errors.address_apac_address = "Campo obrigatório";
        }
        if (!this.form.patient.address.street_code) {
          errors.address_apac_street_code = "Campo obrigatório";
        }
      }

      if(this.requiredField.municipality_apac && !this.form.patient.address.city) {
         // Município de residência
         errors.municipality_apac = "Campo obrigatório";
      }

      if(this.requiredField.cep_apac && !this.form.patient.address.zipCode) {
        // CEP
        errors.cep_apac = "Campo obrigatório";
      }

      if(this.requiredField.ibge_apac && !this.form.patient.address.ibge_code) {
        // Cód. IBGE Município
        errors.ibge_apac = "Campo obrigatório";
      }

      if(this.requiredField.state_apac && !this.form.patient.address.state) {
         // Estado
         errors.state_apac = "Campo obrigatório";
      }

      // if(this.requiredField.cod_main_procedure_apac) {
      //   // Cód. do procedimento principal
      //   // errors.cod_main_procedure_apac = "Campo obrigatório";
      // }

      // if(this.requiredField.name_main_procedure_apac) {
      //   // Nome do procedimento principal
      //   // errors.name_main_procedure_apac = "Campo obrigatório";
      // }

      // if(this.requiredField.qtde_main_apac) {
      //   // Qtde. procedimento principal
      //   // errors.qtde_main_apac = "Campo obrigatório";
      // }

      // if(this.requiredField.cod_secondary_procedure_apac) {
      //   //Cód. do procedimento secundário
      //   // errors.qtde_main_apac = "Campo obrigatório";
      // }

      // if(this.requiredField.name_secondary_procedure_apac) {
      //   // Nome do procedimento secundário
      //   // errors.qtde_main_apac = "Campo obrigatório";
      // }

      // if(this.requiredField.qtde_secondary_apac) {
      //   // Qtde. procedimento secundário
      //   // errors.qtde_main_apac = "Campo obrigatório";
      // }

      
      if(this.requiredField.cid_main_apac &&  !this.form.cid_1 ) {
        // CID 10 Principal
        errors.cid_main_apac = "Campo obrigatório";
      }

      if(this.requiredField.cid_secondary_apac && !this.form.cid_2) {
        // CID 10 Secundário
        errors.cid_secondary_apac = "Campo obrigatório";
      }

      // if(this.requiredField.cod_main_procedure_justification_apac) {
      //   // Cód. do procedimento principal justificativa
      //   // errors.qtde_main_apac = "Campo obrigatório";
      // }
      
      if(this.requiredField.reason_leaving_staying_apac && !this.form.reason_leaving_staying) {
        errors.reason_leaving_staying_apac = "Campo obrigatório";
      }

      if(this.requiredField.diagnosis_apac && !this.form.description) {
        // Descrição do diagnóstico
        errors.diagnosis_apac = "Campo obrigatório";
      }

      if(this.requiredField.observations_apac && !this.form.observation) {
        // Observações
        errors.observations_apac = "Campo obrigatório";
      }
      if(this.requiredField.professional_name_apac && !this.form.requesting_professional) {
        // Nome do profissional solicitante
        errors.professional_name_apac = "Campo obrigatório";
      }

      if(this.requiredField.request_date_apac && !this.form.requesting_date) {
        // Data de solicitação
        errors.request_date_apac = "Campo obrigatório";
      }

      if(this.requiredField.national_requester_card_apac && !this.form.requesting_professional.cns) {
        // Cartão Nacional do Profissional Solicitante
        errors.national_requester_card_apac = "Campo obrigatório";
      }

      if(this.requiredField.authorizing_professional_apac && !this.form.authorizing_professional) {
        // Nome do profissional autorizador
        errors.authorizing_professional_apac = "Campo obrigatório";
      }

      if(this.requiredField.issuer_apac && !this.form.issuing_body_code) {
        // Cód. Orgão Emissor
        errors.issuer_apac = "Campo obrigatório";
      }

      if(this.requiredField.national_authorizer_card_apac && !this.form.authorizing_professional.cns) {
        // Cartão Nacional do Profissional Autorizador
        errors.national_authorizer_card_apac = "Campo obrigatório";
      }

      if(this.requiredField.authorization_date_apac && !this.form.authorization_date) {
        // Data de autorização
        errors.authorization_date_apac = "Campo obrigatório";
      }

      if(this.requiredField.authorization_apac && !this.form.apac_authorization_number) {
        // N° da autorização (APAC)
        errors.authorization_apac = "Campo obrigatório";
      }

      if(this.requiredField.validity_period_apac && !this.form.validity_period_start) {
        // Período de validade de APAC
        errors.validity_period_apac = "Campo obrigatório";
      }

      if(this.requiredField.fantasy_name_apac && !this.form.executing_establishment_name) {
        // Nome fantasia do estab. de saúde executante
        errors.fantasy_name_apac = "Campo obrigatório";
      }

      if(this.requiredField.cnes_executant_apac && !this.form.executing_establishment_cnes) {
        // CNES estabelecimento executante
        errors.cnes_executant_apac = "Campo obrigatório";
      }

      this.errors = errors;
      
      return Object.keys(errors).length;
    },
    getPayload() {
      const payload = {
        sus_information_id: this.sus_information.id,
        guide_number: this.sus_information.current_apac_guide_number,
        clinic_health_plan_id: this.sus_information.clinic_health_plan.id,
        request_number: this.form.request_number,
        cid_1: this.form.cid_1?.id, 
        cid_2: this.form.cid_2?.id, 
        cid_associate: this.form.cid_associate, 
        reason_leaving_staying: this.form.reason_leaving_staying,
        observation: this.form.observation, 
        description: this.form.description, 
        requesting_professional: this.form.requesting_professional,
        authorizing_professional: this.form.authorizing_professional,
        authorization_date: this.form.authorization_date,
        apac_authorization_number: this.form.apac_authorization_number,
        validity_period_start: this.form.validity_period_start, 
        validity_period_end: this.form.validity_period_end, 
        executing_establishment_name: this.form.executing_establishment_name, 
        executing_establishment_cnes: this.form.executing_establishment_cnes,
        mother_phone: this.form.patient.mother_phone,
        clinic_id: this.clinic.id,
        patient_id: this.form.patient.id,
        patient: this.form.patient,
        address: this.form.patient.address,
        procedures: this.form.procedures,
        guide_type: "APAC",
        appointment_id: this.appointmentId,
        requesting_professional_id: this.form.requesting_professional.id,
        authorizing_professional_id: this.form.authorizing_professional.id,
        issuing_body_code: this.form.issuing_body_code,
        requesting_date: this.form.requesting_date,
      };
      return payload;
    },
    async createGuide() {
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.createSusGuide(payload);

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia APAC criada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updatesusGuide(){
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.updateSusGuide(this.localSusGuide.id, payload);

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia APAC atualizada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateProfessional(professional) {
      this.form.requesting_professional = professional;
    },
    updateAutorizador(professional){
      this.form.authorizing_professional = professional;
    },
    updateProcedures(procedures) {
      this.form.procedures = procedures;
    },
    async searchNationality(query) {
      if (query.length > 2) {
        this.nationalities = []
        try {
          const response = await this.api.searchNationality({ term: query })
          this.nationalities = response.data.map(nationality => {
            return {
              label: `${nationality.value}`,
              id: nationality.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async searchCid10(query) {
      if (query.length > 2) {
        this.cid10 = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cid10 = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              id: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    selectCid_1() {
      this.cid_1 = []
    },
    selectCid_2() {
      this.cid_2 = []
    },
    async print() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await api.printSusGuide(this.localSusGuide.id, getCurrentUser()?.name) 
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, 'Guia APAC')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateLocalSusGuide(susGuide) {    
      if (Object.values(susGuide).length > 0) {
          this.localSusGuide = { ...susGuide };

          const isLoading = this.$loading.show();

          this.form = {
            ...this.form,
            apac_authorization_number: this.localSusGuide?.guide?.apac_authorization_number,
            authorization_date: this.formatDate(this.localSusGuide?.guide?.authorization_date),
            authorizing_professional: this.localSusGuide?.guide?.authorizing_professional || {},
            cid_1: this.formatCid(this.localSusGuide?.guide?.cid1),
            cid_2: this.formatCid(this.localSusGuide?.guide?.cid2),
            description: this.localSusGuide?.guide?.description,
            executing_establishment_name: this.localSusGuide?.guide?.executing_establishment_name,
            executing_establishment_cnes: this.localSusGuide?.guide?.executing_establishment_cnes,
            issuing_body_code: this.localSusGuide?.guide?.issuing_body_code,
            observation: this.localSusGuide?.guide?.observation,
            patient: this.localSusGuide.patient || {},
            reason_leaving_staying: this.localSusGuide?.guide?.reason_leaving_staying,
            request_number: this.localSusGuide?.guide?.request_number,
            requesting_date: this.formatDate(this.localSusGuide?.guide?.requesting_date),
            requesting_professional: this.localSusGuide?.guide?.requesting_professional || {},
            validity_period_end: this.formatDate(this.localSusGuide?.guide?.validity_period_end),
            validity_period_start: this.formatDate(this.localSusGuide?.guide?.validity_period_start),
            procedures: { ...this.localSusGuide?.guide?.procedures || [] },
          };

          if(this.localSusGuide?.guide?.requesting_professional?.sus_professional_setting){
            this.form.requesting_professional.cns = this.localSusGuide.guide.requesting_professional.sus_professional_setting.cns;
          }

          if(this.localSusGuide?.guide?.authorizing_professional?.sus_professional_setting){
            this.form.authorizing_professional.cns = this.localSusGuide.guide.authorizing_professional.sus_professional_setting.cns;
          }

          if(this.localSusGuide?.guide?.procedures){
            this.procedures = this.localSusGuide.guide?.procedures.map(item => ({
                'id': item.id,
                'item': item.item,
                'item_id': item.item_id,
                'code': item.item.code,
                'name': item.item.name,
                'attendance_date': moment(item.attendance_date).toDate(),
                'quantity': item.quantity,
                'appointment_item_id': item.appointment_id,
                'total': item.total_value,
                'unitary_value': item.unitary_value,
                'is_principal': item.is_principal
            
            }));

            this.form.procedures = this.procedures;
          }

          isLoading.hide();
          this.loading = false;
      }
    },
    updateLocalPatient(patient) {
      this.form.patient = patient;
        this.form.patient.address = patient.address || {
          zipCode: '',
          ibge_code: null,
          street_code: null,
          address: '',
          complement: '',
          neighborhood: ''
        };
    }
  },
  watch: {
    susGuide: {
      handler(newVal) {
        if (newVal) {
            this.updateLocalSusGuide(newVal);
        } else {
          this.procedures = [];
          this.form.procedures = [];
        }
      },
      immediate: true,
      deep: true
    },
    patient: {
      handler(patient) {
        if (patient) {
          this.updateLocalPatient(patient);
        }
      },
      immediate: true,
      deep: true,
    }
  }
};
</script>

<style lang="scss">
#apac-sus-guide-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .multiselect {
        color: var(--type-active) !important;
      }
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .width-80 {
    width: 80%;
  }

  .form-title {
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }
}
</style>
